import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入 ememnt
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 引入axios
import axios from 'axios'
Vue.prototype.axios = axios;
axios.defaults.withCredentials = true;
if (process.env.VUE_APP_CURRENTMODE == 'test') {
  axios.defaults.baseURL = 'http://ruili.oneworldxr.com';
}
axios.defaults.headers.post['Content-Type'] = 'application/json;charset:utf-8';
axios.defaults.transformRequest = [function (data) {
  data = JSON.stringify(data)
  return data;
}];

// 引入时间线，方便JS动画
import Timeline from '@/common/Timeline'
Vue.use(Timeline);

Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

var Timeline = (function () {

    var timelines = {};
  
    function Timeline(callback, interval, loop, maxtime) {
      this.callback = callback;
      this.interval = interval == null ? 0 : interval;
      this.loop = loop === true;
      this.maxtime = maxtime ? maxtime : 0;
  
      this.rest = this.interval;
      this.complete = false;
      this.total = 0;
      this._pre_total = 0;
      this.id = Timeline.nextId ++;
    }
  
    Timeline.nextId = 0;
  
    Timeline.prototype.constructor = Timeline;
  
    Timeline.prototype.remove = function () {
      this.complete = true;
      delete timelines[this.id];
    }
  
    Timeline.prototype.tick = function (delta) {
      if (!this.complete) {
        this.total += delta;
        this.rest -= delta;
        if (this.rest <= 0) {
          if (this.loop && (this.maxtime <= 0 || this.total < this.maxtime)) {
            this.callback.call(this, this.id, this.maxtime > 0 ? this.total / this.maxtime : this.total, this.total - this._pre_total);
            this._pre_total = this.total;
            this.rest = this.interval;
          } else {
            this.complete = true;
            this.callback.call(this, this.id, 1, delta);
            delete timelines[this.id];
          }
        }
      }
    }
  
    /**
     * 
     * @param {Function} callback 循环执行的方法；
     * @param {Number} interval 下次执行的时间间隔，为0时为1帧；
     * @param {Boolean} loop 是否循环，为false,callback执行一次；为true，callback执行直到maxtime；
     * @param {Number} maxtime loop为true时，callback最多执行到的时间，为0时永久执行。
     * @returns timeline 对象
     */
    Timeline.addTimeline = function (callback, interval, loop, maxtime) {
      let timeline = new Timeline(callback, interval, loop, maxtime);
      timelines[timeline.id] = timeline;
      return timeline;
    }

    Timeline.removeTimeline = function (id) {
      let timeline = timelines[id];
      if (timeline) {
        timeline.complete = true;
        delete timelines[id];
      }
    }
  
    Timeline.addLoop = function (callback, interval) {
      let timeline = new Timeline(callback, interval, true);
      timelines[timeline.id] = timeline;
      return timeline;
    }
  
    Timeline.doNextFrame = function (callback) {
      return Timeline.doOnce(callback, 0);
    }
  
    Timeline.doOnce = function (callback, time) {
      let timeline = new Timeline(callback, time);
      timelines[timeline.id] = timeline;
      return timeline;
    }
  
    Timeline.tickAll = function (delta) {
      var ticked = false;
      for (let i in timelines) {
        timelines[i].tick(delta);
        ticked = true;
      }
      return ticked;
    } 
  
    var now = Date.now();
    Timeline.start = function () {
      if (Timeline.started !== true) {
        Timeline.started = true;
        now = Date.now();
        next();
      }
    }

    function next() {
      requestAnimationFrame(() => {
        if (!Timeline.stopped) {
          next();

          let newNow = Date.now();
          let delta = newNow - now;
          now = newNow;

          Timeline.tickAll(delta);
        } else {
          Timeline.started = false;
        }
      });
    }
  
    Timeline.stop = function () {
      Timeline.stopped = true;
    }
  
    Timeline.destroy = function () {
      Timeline.stop();
      timelines = {};
    }
  
    Timeline.install = function (Vue) {
      Vue.prototype.addTimeline = function (callback, interval, loop, maxtime) {
        return Timeline.addTimeline(callback, interval, loop, maxtime);
      }
      Vue.prototype.$addTimeline = Vue.prototype.addTimeline;
  
      Vue.prototype.removeTimeline = function (id) {
        return Timeline.removeTimeline(id);
      }
      Vue.prototype.$removeTimeline = Vue.prototype.removeTimeline;
  
      Timeline.start();
    }
  
    return Timeline;
  
  }) ();
  
  export default Timeline;
<template>
  <div id="app" :style="{ transform: `scale(${zoomX},${zoomY})` }">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      zoomX: this.$route.query.zoomX ? parseFloat(this.$route.query.zoomX) : 1,
      zoomY: this.$route.query.zoomY ? parseFloat(this.$route.query.zoomY) : 1
    }
  }, 
  watch: {
    $route() {
      this.zoomX = this.$route.query.zoomX ? parseFloat(this.$route.query.zoomX) : 1;
      this.zoomY = this.$route.query.zoomY ? parseFloat(this.$route.query.zoomY) : 1;
    },
  },
  mounted() {
    if (process.env.VUE_APP_CURRENTMODE == 'test') {
      document.body.style.backgroundImage = "url(./67e77f7cb0e37bb5f8856b54c12ed33.png)";
    }
  }
}
</script>

<style lang="scss">
* {
  box-sizing: content-box;
  margin: 0;
  padding: 0;
}
body {
  background: transparent;
  // background-image: url(./assets/images/tabble-ruili/67e77f7cb0e37bb5f8856b54c12ed33.png);
  background-size: 100% 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  text-align: center;
  overflow: hidden;
  transform-origin: 0 0;
}
</style>

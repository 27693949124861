import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/production-machining-9600",
  },
  {
    path: "/production-machining-9600",
    name: "DailyProductStatus-2260",
    component: () => import("../views/DailyProductStatus-2260.vue"),
  },
  {
    path: "/production-machining-11520",
    name: "DailyProductStatus-1394",
    component: () => import("../views/DailyProductStatus-1394.vue"),
  },
  {
    path: "/production-diecast-11520",
    name: "DailyProductStatus-1732",
    component: () => import("../views/DailyProductStatus-1732.vue"),
  },
  {
    path: "/production-diecast-9600",
    name: "DailyProductStatus-1896",
    component: () => import("../views/DailyProductStatus-1896.vue"),
  },
  {
    path: "/cnc-machining-9600",
    name: "CNCProLineInfoBig",
    component: () => import("../views/AboutView-big.vue"),
  },
  {
    path: "/cnc-machining-11520",
    name: "CNCProLineInfoSmall",
    component: () => import("../views/AboutView-small.vue"),
  },

  {
    path: "/agv-machining-9600",
    name: "AgvABig",
    component: () => import("../views/tabble-ruili-big.vue"),
  },
  {
    path: "/agv-machining-11520",
    name: "AgvASmall",
    component: () => import("../views/tabble-ruili-small.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
